import React, { useEffect, useContext,useState } from "react";
import { useNavigate } from "react-router-dom";
import MonthlyBar from "./MonthlyBar";
import WeeklyBar from "./WeeklyBar";
import ClientModifications from "./ClientModifications";
import LineChart from "./LineChart";
import { FaRegEye } from "react-icons/fa";
import { BsArrowUpRight, BsArrowDownRight } from "react-icons/bs";
import { AuthContext } from "../../components/authcontext/AuthContext";
import { CaseService } from "../../api/CaseService";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import ClientCasesMonthly from "./ClientCasesMonthly";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ClientCasesWeekly from "./ClientCasesWeekly";
const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [dashboardStats,setDashboardStats]=useState({})
  const {currentUser,updateUser} = useContext(AuthContext)
  console.log(currentUser,"uuuuuuuuma")
  const userType = currentUser?.role_name
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  //get case details
  const getDashboardData = async () => {
    try {
      const result = await CaseService.getDashboardStats();

      if (result?.data?.status == 200) {
        console.log(result);
        setDashboardStats(result?.data?.data)
     
    
      } 
      else {
        if(result?.data?.message == "Unauthenticated.") {
          navigate('/login')
        }
        toast.error(result?.data?.message, {
         
          autoClose: 2000,
          pauseOnHover: true,
          draggable: true,
          
        });
      }
    } catch (error) {
      toast.error(error?.result?.data?.errors[0], {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    getDashboardData()
  },[])
  return (
    <div className="dashboard-container">
       {loading && (
        <div className="loader-parent">
          <div className="_loader"></div>
        </div>
      )}
      <ToastContainer />
      <div className="small-boxes-wrapper">
        
          <div className="small-box">
            <div className="flex-between">
              <div className="title">In Process</div>
             
            </div>
            <div className="flex-between">
              <div className="number">
                {parseInt(dashboardStats?.cases_2_count) + parseInt(dashboardStats?.cases_3_count)}
              </div>
              <div className="percentage">
              {  ((parseInt(dashboardStats?.cases_2_count) + parseInt(dashboardStats?.cases_3_count)) > 0 &&  dashboardStats?.cases_count > 0) ?(((parseInt(dashboardStats?.cases_2_count) + parseInt(dashboardStats?.cases_3_count)) / dashboardStats?.cases_count) * 100).toFixed(2) : '0'} % { ((parseInt(dashboardStats?.cases_2_count) / dashboardStats?.cases_count) * 100) >= 50 ? <BsArrowUpRight /> : <BsArrowDownRight />}
              </div>
            </div>
          </div>
          {/* <div className="small-box">
            <div className="flex-between">
              <div className="title">Need More Info</div>
             
            </div>
            <div className="flex-between">
              <div className="number">
                {parseInt(dashboardStats?.cases_4_count)}
              </div>
              <div className="percentage">
              { (parseInt(dashboardStats?.cases_4_count) > 0 &&  dashboardStats?.cases_count > 0) ?  ( (parseInt(dashboardStats?.cases_4_count) / dashboardStats?.cases_count) * 100).toFixed(2) : '0'} % { ((parseInt(dashboardStats?.cases_4_count) / dashboardStats?.cases_count) * 100) >= 50 ? <BsArrowUpRight /> : <BsArrowDownRight />}
              </div>
            </div>
          </div> */}
          <div className="small-box">
            <div className="flex-between">
              <div className="title">Need Case modifications </div>
             
            </div>
            <div className="flex-between">
              <div className="number">
                {parseInt(dashboardStats?.cases_8_count)}
              </div>
              <div className="percentage">
              { (parseInt(dashboardStats?.cases_8_count) > 0 &&  dashboardStats?.cases_count > 0) ? ((parseInt(dashboardStats?.cases_8_count) / dashboardStats?.cases_count) * 100).toFixed(2) : '0'} % { ((parseInt(dashboardStats?.cases_8_count) / dashboardStats?.cases_count) * 100) >= 50 ? <BsArrowUpRight /> : <BsArrowDownRight />}
              </div>
            </div>
          </div>
          
              <div className="small-box">
                <div className="flex-between">
                  <div className="title">Pending Approval</div>
                  
                </div>
                <div className="flex-between">
                  <div className="number">
                    {dashboardStats?.cases_7_count}
                  </div>
                  <div className="percentage">
                    {(dashboardStats?.cases_7_count > 0 &&  dashboardStats?.cases_count > 0) ?  ((dashboardStats?.cases_7_count / dashboardStats?.cases_count) * 100).toFixed(2) : '0'}%
                    { ((parseInt(dashboardStats?.cases_7_count) / dashboardStats?.cases_count) * 100) >= 50 ? <BsArrowUpRight /> : <BsArrowDownRight />}
                  </div>
                </div>
              </div>
              <div className="small-box">
                <div className="flex-between">
                  <div className="title">Step-Files Ready</div>
                  
                </div>
                <div className="flex-between">
                  <div className="number">
                  {parseInt(dashboardStats?.cases_11_count) + parseInt(dashboardStats?.cases_12_count)}
                  </div>
                  <div className="percentage">
              {  ((parseInt(dashboardStats?.cases_11_count) + parseInt(dashboardStats?.cases_12_count)) > 0 &&  dashboardStats?.cases_count > 0) ?(((parseInt(dashboardStats?.cases_11_count) + parseInt(dashboardStats?.cases_12_count)) / dashboardStats?.cases_count) * 100).toFixed(2) : '0'} % { ((parseInt(dashboardStats?.cases_11_count) / dashboardStats?.cases_count) * 100) >= 50 ? <BsArrowUpRight /> : <BsArrowDownRight />}
              </div>
                </div>
              </div>
        
        
      </div>
      <div className="row mb-4">
        <div className="col-12 col-md-6">
          <div className="title-grap">Daily Cases</div>
        <div className="chart-top">
            <WeeklyBar dashboard={dashboardStats} />
          </div>
        </div>
        <div className="col-12 col-md-6">
        <div className="title-grap">Monthly Cases</div>
        <div className="chart-top">
        <MonthlyBar dashboard={dashboardStats} />
      </div>
        </div>
      </div>
      {userType == 'super_admin' && <div className="row mb-4">
        <div className="col-12 col-md-7">
        <div className="title-grap">Clients Cases modifications ratio </div>
        <div className="chart-top">
        <ClientModifications dashboard={dashboardStats} />
        
        </div>
        </div>
        <div className="col-12 col-md-5">
        <div className="title-grap">All cases modifications ratio</div>
        <div className="chart-top">
          <div className="over-all-stats-wrapper">
          <div className="parent-circle">
        <CircularProgressbar
        value={dashboardStats?.over_all_ratio_count?.toFixed(1)}
        text={`${dashboardStats?.over_all_ratio_count?.toFixed(1)}%`}
        styles={buildStyles({
          // Customize the path color
          pathColor: `#064469`,
          // Customize the text color
          textColor: '#064469',
          // Customize the trail color (background)
          trailColor: '#d6d6d6',
          // Customize the background color
          backgroundColor: '#3e98c7',
        })}
      />
        </div>
          </div>
        </div>
        </div>
        </div> }
       {userType == 'super_admin' && <div className="row mb-4">
        <div className="col-12 col-md-6">
        <div className="title-grap">Client Monthly Cases </div>
        <div className="chart-top">
        <ClientCasesMonthly dashboard={dashboardStats} />
        </div>
        </div>
        <div className="col-12 col-md-6">
        <div className="title-grap">Client Weekly Cases </div>
        <div className="chart-top">
        <ClientCasesWeekly dashboard={dashboardStats} />
        </div>
        </div>
      </div>}
      
     
    
    </div>
  );
};

export default Dashboard;
