import React, { useEffect,useState } from 'react'
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { Link,useNavigate } from 'react-router-dom';
import { GrView } from "react-icons/gr";
import Dummy from '../../images/dummy.jpeg'
import DataTable from 'react-data-table-component';
import { BiBorderRadius } from 'react-icons/bi';
import { CaseService } from '../../api/CaseService';
import { AuthContext } from '../../components/authcontext/AuthContext';
import moment from 'moment';
import { useContext } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";


const Cases = () => {
  const {currentUser} = useContext(AuthContext)
  const userType = currentUser?.roles[0]?.name
  const permissions =currentUser?.permissions
  console.log("permissons",permissions)
  console.log(permissions)
  const navigate = useNavigate();
  const [dataa,setDataa]=useState([])
  const [timerData, setTimerData] = useState({});
  const [caseid,setCaseid]=useState('')
  const [loading, setLoading] = useState(false);
  const [isTimeRunningLow,setIsTimeRunningLow]=useState(false)
 
  
  const getAllCases = async (caseid) => {
    setLoading(true)
  
    try {
         const result = await CaseService.getCases(caseid);
       
         
        
         if (result?.data?.status == 200) {
          const transformedCases = result?.data?.data?.map((item) => {
            const startDate = new Date(item?.start_date_time);
            const endTime = new Date(
              startDate.getTime() + (item?.expected_time !=null ? parseInt(item?.expected_time) * 60 * 60 * 1000 : 0)
                
            );
            const currentTime = new Date().getTime();
            let remainingTimeMs = endTime - currentTime;
  
            if (remainingTimeMs < 0) {
              remainingTimeMs = 0;
            }
  
            const hours = Math.floor(remainingTimeMs / (1000 * 60 * 60));
            const minutes = Math.floor(
              (remainingTimeMs % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((remainingTimeMs % (1000 * 60)) / 1000);
            const formattedRemainingTime = `${hours}h ${minutes}m ${seconds}s`;
         
           
            return {
              id: item?.guid,
              caseId: item?.case_id,
              status: item.status,
              name: item?.name,
              email: item?.email,
              gender: item?.gender,
              created: `${moment(item?.created_at).format(
                "ddd, MMM DD YYYY"
              )} :  ${moment(item?.created_at).format("HH:mm:ss")}`,
              clinic: item?.created_user?.username
                ? item?.created_user?.username
                : "null",
              planner: item?.planner?.username
                ? `${item?.planner?.first_name} ${item?.planner?.last_name}`
                : "null",
              remainingTime: formattedRemainingTime,
              remainingTimeMs // Add remaining time to case
            };
          });
  
          setDataa(transformedCases);
          // setTimerData(transformedCases.reduce((acc, item) => ({ ...acc, [item.id]: item.remainingTime }), {}));
        } 
        else {
          if(result?.data?.message == "Unauthenticated.") {
            navigate('/login')
          }
          toast.error(result?.data?.message, {
           
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            
          });
        }
       
       
    } catch (error) {
      toast.error(error?.result?.data?.errors[0], {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        
      });
     
      
    }
    finally {
      setLoading(false)
    }
  };


  
  useEffect(()=>{
    getAllCases(caseid)
  },[caseid])
  //delete case 
  const deleteCaseFunction = async (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this case?");

    if (!isConfirmed) {
      // If the case cancels, exit the function early
      return;
    }
    setLoading(true)
  
    try {
         const result = await CaseService.deleteCase(id);
       
         
        
        if(result?.data?.status == 200){
      
          toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            
          });
          getAllCases()
        } 
        else {
          if(result?.data?.message == "Unauthenticated.") {
            navigate('/login')
          }
          toast.error(result?.data?.message, {
           
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            
          });
        }
       
       
    } catch (error) {
      toast.error(error?.result?.data?.errors[0], {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        
      });
     
      
    }
    finally {
      setLoading(false)
    }
  };
   
    const columns = [
        {
            name: 'Case Id',
            selector: row => row.caseId,
            sortable: true,
        },
       
        {
          name: 'Case Status',
          selector: row => <span className='case-status-tag'>{row?.status == '1' ? 'New Case' : row?.status == '2' ? "In Planning" : row?.status == '3' ? "Ready for QA" : row?.status == '4' ? "Need More Info" : row?.status == '5' ? "Case updated by ortho" : row?.status == '6' ? "Rejected By QA" : row?.status == '7' ? "Pending Ortho Check" : row?.status == '8' ? "Need Modifications" : row?.status == '9' ? "Pending Step Files" : row?.status == '10' ? "Pending Container files" : row?.status == '11' ?  "Stl files ready" : row?.status == '12' ?  "Container files ready" : row?.status == '13' ? "Need Stl File Modifications" : row?.status == '14' ? "Need Container File Modifications" : row?.status == '15' ? "Case Completed" : '' }</span>,
          sortable: true,
      },
      
      {
        name: 'Remaining Time',
        selector: row => <span className={`${(row.remainingTimeMs <= 8 * 60 * 60 * 1000) ? 'red-time' : (row.remainingTimeMs <= 16 * 60 * 60 * 1000) ? 'yellow-time' : 'green-time'}`} >{row.remainingTime}</span>,
        sortable: true,
    },
        {
            name: 'Patient Name',
            selector: row => row.name,
            sortable: true,
        },
        
       
        {
            name: 'Patient Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Gender',
            selector: row => row.gender,
            sortable: true,
        },
        {
          name: 'Case Created',
          selector: row => row.created,
          sortable: true,
      },
        {
          name: 'Clinic',
          selector: row => row.clinic,
          sortable: true,
      },
      {
        name: 'Planner',
        selector: row => row.planner,
        sortable: true,
    },
       
       
        {
            name: 'Action',
            
            cell: row => (
                <div className='icons__parent'>
                  {(permissions?.includes("patient-cases-update") && (row?.status == '4' || row?.status == '1')) &&  <Link title='Edit Case' className='button-icon' to={`/update-case/${row.id}`} >
                        <FiEdit  />
                    </Link>}
                    {permissions?.includes("patient-cases-detail") &&     <Link title='View Case' className='button-icon'  to={`/case-detail/${row.id}`}>
                        <GrView   />
                    </Link>}
                    {permissions?.includes("patient-cases-delete") &&    <div  title='Delete Case' className='button-icon' onClick={() => deleteCaseFunction(row.id)}>
                        <MdDeleteOutline  />
                    </div>}
                    
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
  
 


// Function to handle delete action

  return (
    <div className='users-wrapper'>
      {loading && <div className="loader-parent"><div className="_loader">
        
        </div></div>}
      <ToastContainer />
      <div className="d-flex justify-content-between mb-3">
        <div className="searc-wrapper">
          <input className='input' type="text" placeholder='Search case by case id' value={caseid} onChange={(e)=>setCaseid(e.target.value)} />
        </div>
        {permissions?.includes("patient-cases-store") && <Link to={'/create-case'} className="button">Create New Case </Link> }
        
      </div>
          <DataTable
			columns={columns}
			data={dataa}
     
      pagination
            paginationPerPage={10} 
            paginationRowsPerPageOptions={[5, 10, 20]}
           
           
		/>
    </div>
  )
}

export default Cases