import axios from 'axios';

// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.baseURL = 'https://109.123.235.98/admin-panel/public/api'

// axios.defaults.headers.common['Authorization'] = 'Bearer'+AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/json';


//add case

async function createCase(data,onUploadProgress){
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
    
     
       var config = {
           method: 'post',
           url: '/patient_cases/store',
           headers:{
            'Accept': 'application/json', 
            // 'Content-Type': 'application/json', 
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer '+AUTH_TOKEN
          },
           data : data,
           onUploadProgress: onUploadProgress,
           
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
     return err
    }
  }
//update case

async function updateCase(id,data,onUploadProgress){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'post',
         url: `/patient_cases/update/${id}`,
         headers:{
          'Accept': 'application/json', 
          // 'Content-Type': 'application/json', 
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+AUTH_TOKEN
        },
         data : data,
         onUploadProgress: onUploadProgress,
           
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
  //get all cases

  async function getCases(case_id){
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
      const queryParams = new URLSearchParams();
      if (case_id !== undefined) queryParams.append('search', case_id);
       var config = {
           method: 'get',
           url: `/patient_cases?${queryParams}`,
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json', 
            // "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer '+AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
     return err
    }
    }
     //get completed cases

  async function getCompletedCases(){
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
       var config = {
           method: 'get',
           url: '/patient_case/completed_cases',
           headers:{
            'Accept': 'application/json', 
            'Content-Type': 'application/json', 
            // "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer '+AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
     return err
    }
    }
   //get case detail
   
  async function getCaseDetail(id){
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
       var config = {
           method: 'get',
           url: `/patient_cases/${id}`,
           headers:{
            'Accept': 'application/json', 
            // 'Content-Type': 'application/json', 
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer '+AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
     return err
    }
    }
     //delete case

  async function deleteCase(id){
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
       var config = {
           method: 'delete',
           url: `/patient_cases/${id}`,
           headers:{
            'Accept': 'application/json', 
            // 'Content-Type': 'application/json', 
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer '+AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
     return err
    }
    }


    //create peniding-approval

async function createPendingApproval(data){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'post',
         url: '/pending_approvals/store',
         headers:{
          'Accept': 'application/json', 
          // 'Content-Type': 'application/json', 
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+AUTH_TOKEN
        },
         data : data
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
}
//update PendingApproval

async function updatePendingApproval(id,data){
try {
  let AUTH_TOKEN = window.localStorage.getItem('token');
   var config = {
       method: 'post',
       url: `/pending_approvals/update/${id}`,
       headers:{
        'Accept': 'application/json', 
        // 'Content-Type': 'application/json', 
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer '+AUTH_TOKEN
      },
       data : data
   };
   
 return await axios(config)
   .then(function (response) {
     return response;
   })
   .catch(function (error) {
     return error.response
 });
} catch (err) {
 return err
}
}
//get all PendingApproval

async function getPendingApprovals(){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: '/pending_approvals',
         headers:{
          'Accept': 'application/json', 
          // 'Content-Type': 'application/json', 
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+AUTH_TOKEN
        },
         data : ''
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
  }
 //get case PendingApprovalDetai
 
async function getPendingApprovalDetail(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: `/pending_approvals/${id}`,
         headers:{
          'Accept': 'application/json', 
          // 'Content-Type': 'application/json', 
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+AUTH_TOKEN
        },
         data : ''
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
  }
   //delete PendingApproval

async function deletePendingApproval(id){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'delete',
         url: `/pending_approvals/${id}`,
         headers:{
          'Accept': 'application/json', 
          // 'Content-Type': 'application/json', 
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+AUTH_TOKEN
        },
         data : ''
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
  }

  //assign case to planner
  async function assignCase(data){
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
       var config = {
           method: 'post',
           url: `/patient_cases/case_assign_to`,
           headers:{
            'Accept': 'application/json', 
            // 'Content-Type': 'application/json', 
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer '+AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
     return err
    }
    }
  // create case plan
  async function createCasePlan(data){
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
       var config = {
           method: 'post',
           url: `/case_plans/store`,
           headers:{
            'Accept': 'application/json', 
            // 'Content-Type': 'application/json', 
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer '+AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
     return err
    }
    }
  // update case plan
  async function updateCasePlan(id,data){
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
       var config = {
           method: 'post',
           url: `/case_plans/update/${id}`,
           headers:{
            'Accept': 'application/json', 
            // 'Content-Type': 'application/json', 
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer '+AUTH_TOKEN
          },
           data : data
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
     return err
    }
    }
   //get all plans
   async function getCasePlans(){
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
       var config = {
           method: 'get',
           url: '/case_plans',
           headers:{
            'Accept': 'application/json', 
            // 'Content-Type': 'application/json', 
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer '+AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
     return err
    }
    }
   //get case detail
   
  async function getCasePlan(id){
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
       var config = {
           method: 'get',
           url: `/case_plans/${id}`,
           headers:{
            'Accept': 'application/json', 
            // 'Content-Type': 'application/json', 
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer '+AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
     return err
    }
    }
     //delete case

  async function deleteCasePlan(id){
    try {
      let AUTH_TOKEN = window.localStorage.getItem('token');
       var config = {
           method: 'delete',
           url: `/case_plans/${id}`,
           headers:{
            'Accept': 'application/json', 
            // 'Content-Type': 'application/json', 
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer '+AUTH_TOKEN
          },
           data : ''
       };
       
     return await axios(config)
       .then(function (response) {
         return response;
       })
       .catch(function (error) {
         return error.response
     });
    } catch (err) {
     return err
    }
    }
// update case status
async function updateCaseStatus(data,onUploadProgress){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'post',
         url: `/patient_cases/update_patient_case_status`,
         headers:{
          'Accept': 'application/json', 
          // 'Content-Type': 'application/json', 
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+AUTH_TOKEN
        },
         data : data,
         onUploadProgress:onUploadProgress
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
  }

  // update case status
async function updateCaseToSubmission(id,onUploadProgress){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: `/patient_cases/verified_by_client/${id}`,
         headers:{
          'Accept': 'application/json', 
          // 'Content-Type': 'application/json', 
          "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+AUTH_TOKEN
        },
         data : '',
         onUploadProgress:onUploadProgress
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
  }
  //dashboard api

    // update case status
async function getDashboardStats(){
  try {
    let AUTH_TOKEN = window.localStorage.getItem('token');
     var config = {
         method: 'get',
         url: `/admin/dashboard`,
         headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json', 
          // "Content-Type": "multipart/form-data",
          'Authorization': 'Bearer '+AUTH_TOKEN
        },
         data : ''
         
     };
     
   return await axios(config)
     .then(function (response) {
       return response;
     })
     .catch(function (error) {
       return error.response
   });
  } catch (err) {
   return err
  }
  }
  export const CaseService= { createCase,updateCase,getCases,getCompletedCases ,getCaseDetail,deleteCase,createPendingApproval,updatePendingApproval,getPendingApprovals,getPendingApprovalDetail,deletePendingApproval,assignCase,createCasePlan,updateCasePlan,getCasePlan,getCasePlans,deleteCasePlan,updateCaseStatus,updateCaseToSubmission,getDashboardStats}